.services-article {
  position: relative;
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    &:after {
      content: '';
      position: absolute;
      width: 35%;
      height: scale-rem-value(15);
      left: 0;
      bottom: 0;

      [data-page-id="bereavement-care"] & {
        background-color: #72BEA3;
      }

      [data-page-id="tissue-return"] & {
        background-color: #F28860;
      }

      [data-page-id="coronial-support"] & {
        background-color: #8E75DC;
      }

      [data-page-id="sudi-information"] & {
        background-color: #E55B84;
      }
    }
  }

  .header-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $beige;

    @include breakpoint(md) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    // HEADER

    .header {
      display: flex;
      flex-direction: column;

      @include breakpoint(md) {
        width: 100vw;
        height: 30vw;
        flex-direction: row-reverse;
      }

      .image {
        position: relative;
        width: 100vw;
        height: 100vw;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        
        // [data-page-id="bereavement-care"] & {
        //   background-position: center top;
        // }

        // [data-page-id="tissue-return"] & {
        //   background-size: 120%;
        // }

        // [data-page-id="sudi-information"] & {
        //   background-size: 150%;
        // }

        // [data-page-id="coronial-support"] & {
        //   background-size: 140%;
        // }
        
        @include breakpoint(md) {
          position: absolute;
          right: 0;
          top: 0;
          width: scale-rem-value(640);
          height: scale-rem-value(560);

          // [data-page-id="bereavement-care"] & {
          //   width: 900rem;
          // }

          // [data-page-id="coronial-support"] & {
          //   background-size: 120%;
          // }
        }

        @include breakpoint(lg) {
          width: scale-rem-value(940);
          height: scale-rem-value(700);
        }
      }

      .title {
        position: relative;
        font-family: 'PlayfairDisplay-Bold';
        color: #363843;
        padding: 0 scale-rem-value(40);
        margin: 0;

        @include font-size-headline();

        @include breakpoint(md) {
            z-index: 1;
            width: 100vw;
            align-self: center;
            padding: scale-rem-value(190) scale-rem-value(100) scale-rem-value(100) scale-rem-value(100);
        }

        @include breakpoint(lg) {
            padding: scale-rem-value(260) scale-rem-value(100) scale-rem-value(100) scale-rem-value(100);
        }
      }
    }

    // WWD (What we do)

    .wwd {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 90vw;
      margin: scale-rem-value(30) 0 0 0;
      padding: scale-rem-value(40);
      z-index: 4;

      @include breakpoint(md) {
        width: 43%;
        padding: scale-rem-value(56) scale-rem-value(56) scale-rem-value(56) scale-rem-value(100);
        margin: 0;
      }

      [data-page-id="bereavement-care"] & {
        background-color: #72BEA3;
      }

      [data-page-id="tissue-return"] & {
        background-color: #F28860;
      }

      [data-page-id="coronial-support"] & {
        background-color: #8E75DC;
      }

      [data-page-id="sudi-information"] & {
        background-color: #E55B84;
      }

      .title {
        font-family: 'PlayfairDisplay-Bold';
        color: #FFFFFF;
        margin: 0 0 scale-rem-value(20) 0;
        @include font-size-subhead();
      }

      .copy {
        color: #FFFFFF;
        margin: 0;
        @include font-size-body-copy();
      }
    }

    // WWLA (Who we look after)

    .wwla {
      position: relative;
      background-color: #363843;
      display: flex;
      flex-direction: column;
      margin: scale-rem-value(-190) 0 0 0;
      padding: scale-rem-value(230) scale-rem-value(40) scale-rem-value(40) scale-rem-value(40);

      @include breakpoint(md) {
        padding: scale-rem-value(53) scale-rem-value(144);
        padding-left: calc(43% + 12rem);
        width: 100vw;
        margin: scale-rem-value(-200) 0 0 0;
        align-self: flex-end;
        z-index: 3;

        &.hide-bottom-bar {
          &:after {
            display: none;
          }
        }

        &:after {
          content: '';
          position: absolute;
          width: 50vw;
          height: scale-rem-value(15);
          right: 0;
          bottom:scale-rem-value(-15);

          [data-page-id="bereavement-care"] & {
            background-color: #72BEA3;
          }
    
          [data-page-id="tissue-return"] & {
            background-color: #F28860;
          }
    
          [data-page-id="coronial-support"] & {
            background-color: #8E75DC;
          }
    
          [data-page-id="sudi-information"] & {
            background-color: #E55B84;
          }
        }
      }

      .title {
        font-family: 'PlayfairDisplay-Bold';
        color: #FFFFFF;
        margin: 0 0 scale-rem-value(20) 0;
        @include font-size-subhead();
      }

      .copy {
        color: #FFFFFF;
        margin: 0;
        @include font-size-body-copy();
      }

      .list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
          flex-direction: row;
          flex-wrap: wrap;
          font-size: scale-rem-value(16);
        }

        @include breakpoint(lg) {
          font-size: scale-rem-value(24);
        }

        li {
          font-size: scale-rem-value(14);
          font-weight: bold;
          color: #FFFFFF;
          margin: scale-rem-value(10) 0;
          display: flex;
          align-items: center;

          @include breakpoint(md) {
            width: 50%;
            font-size: scale-rem-value(16);
          }

          @include breakpoint(lg) {
            font-size: scale-rem-value(24);
          }

          &:before {
            content: '';
            display: flex;
            width: scale-rem-value(10);
            height: 1px;
            background-color: #FFFFFF;
            margin: 0 scale-rem-value(10) 0 0;
          }
        }
      }
    }
  }

  // HOW IT WORKS

  .how-it-works {
    background-color: $beige;
    padding: scale-rem-value(40) scale-rem-value(40) scale-rem-value(140) scale-rem-value(40);
    display: flex;
    flex-direction: column;

    
    @include breakpoint(xs) {
      &.original-padding {
        padding: scale-rem-value(40);
      }
    }

    @include breakpoint(md) {
      padding: scale-rem-value(80) scale-rem-value(100);
      flex-direction: row;
    }

    .title {
      font-family: 'PlayfairDisplay-Bold';
      color: #363843;
      margin: 0 0 scale-rem-value(20) 0;
      @include font-size-subhead();

      @include breakpoint(md) {
        width: 40%;
        padding: 0 scale-rem-value(200) 0 0;
      }
    }

    .copy-block {
      @include breakpoint(md) {
        width: 60%;
        padding: 0 scale-rem-value(200) 0 0;
      }

      .copy {
        color: #363843;
        margin: scale-rem-value(20) 0;
        
        @include font-size-body-copy();
      }
    }

  }

  // JOURNEY

  .journey {
    position: relative;
    background-color: $beige;
    padding: scale-rem-value(40) scale-rem-value(40) scale-rem-value(350) scale-rem-value(40);
    width: 100vw;

    @include breakpoint(xs) {
      &.original-padding {
        padding: scale-rem-value(40) scale-rem-value(40) scale-rem-value(0) scale-rem-value(40);
      }
    }

    @include breakpoint(md) {
      padding: scale-rem-value(100) scale-rem-value(100) scale-rem-value(350) scale-rem-value(100);
      width: calc(100vw - 4.16667rem);

      &.original-padding {
        padding: scale-rem-value(100) scale-rem-value(100) scale-rem-value(70) scale-rem-value(100);
      }
    }

    .top-bar {
      display: none;

      @include breakpoint(md) {
        position: absolute;
        display: flex;
        width: 50vw;
        height: scale-rem-value(15);
        right: scale-rem-value(-50);
        top: 0;
        transform: all 0.35s ease-in-out;

        &.sticky {
          position: fixed;
          right: 0;
          width: 50vw;
          z-index: 3;
        }
  
        [data-page-id="bereavement-care"] & {
          background-color: #72BEA3;
        }
  
        [data-page-id="tissue-return"] & {
          background-color: #F28860;
        }
  
        [data-page-id="coronial-support"] & {
          background-color: #8E75DC;
        }
  
        [data-page-id="sudi-information"] & {
          background-color: #E55B84;
        }
      }
    }

    .title {
      font-family: 'PlayfairDisplay-Bold';
      color: #363843;
      margin: 0 0 scale-rem-value(60) 0;
      @include font-size-subhead();

      .title-bg {
        display: none;
  
        @include breakpoint(md) {
          display: inherit;
          position: absolute;
          display: flex;
          top: scale-rem-value(-40);
          left: scale-rem-value(-100);
          width: 0vw;
          height: scale-rem-value(140);
          background-color: #363843;
          transition: width 0.35s ease-in-out;
          z-index: -1;
        }
      }

      @include breakpoint(md) {
        margin: 0 0 scale-rem-value(40) 0;
        transition: color 0.35s ease-in-out;
        position: absolute;
        top: scale-rem-value(100);

        &.sticky {
          position: sticky;
          top: scale-rem-value(40);
          z-index: 2;
          color: #ffffff;

          .title-bg {
            width: calc(100vw - 20.83333rem);
          }
        }
      }

      @include breakpoint(lg) {
        margin: 0 0 scale-rem-value(120) 0;
      }
    }

    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 scale-rem-value(80) 0;

      @include breakpoint(md) {
        flex-direction: row;
        align-items: center;
        margin: 0;
        height: 60vh;
      }

      .image {
        width: scale-rem-value(230);
        height: scale-rem-value(230);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include breakpoint(md) {
          width: 40%;

          &.landscape {
            background-size: 70%;
          }
        }

        @include breakpoint(lg) {
          &.landscape {
            background-size: 50%;
          }
        }
      }

      .copy {
        color: #363843;
        margin: scale-rem-value(40) 0 0 0;
        @include font-size-title-copy();

        @include breakpoint(md) {
          width: 60%;
          margin: 0 0 0 scale-rem-value(40);
          padding: 0 scale-rem-value(150) 0 0;
        }

        .link {
          color: #00A4CD;
          margin: scale-rem-value(20) 0 0 0;
          @include font-size-title-copy();
        }
      }
    }

    .sticky-nav {
      display: none;

      @include breakpoint(md) {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: scale-rem-value(50);
        top: 50vh;
        left: calc(100vw - 11.66667rem);
        transform: translate(0, -50%);
        align-items: flex-end;
        z-index: 2;
      }

      .sticky-nav-btn {
        display: flex;
        align-items: center;
        margin: 0 0 scale-rem-value(20) 0;
        cursor: pointer;

        &:hover {
          .label {
            color: #363843;
          }
        }

        .line {
          display: flex;
          width: scale-rem-value(0);
          height: 1px;
          background-color: #7C8096;
          margin: 0 scale-rem-value(10) 0 0;
          transition: width 0.35s ease-in-out;
          pointer-events: none;
        }

        .label {
          @include font-size-body-copy();
          font-weight: bold;
          color: #7C8096;
          transition: color 0.35s ease-in-out;
          pointer-events: none;
        }

        &.active {
          .line {
            width: scale-rem-value(30);
            background-color: #363843;
          }
          .label {
            color: #363843;
          }
        }
      }
    }
  }

  // HWSP (How We Support Parents)

  .hwsp {
    position: relative;
    padding: scale-rem-value(40);
    align-self: flex-end;
    width: 90vw;
    margin: scale-rem-value(-380) 0 0 0;
    z-index: 5;
    background-color: #72BEA3;

    @include breakpoint(md) {
      padding: scale-rem-value(80) scale-rem-value(150);
      width: 70%;
    }

    .bereavement & {
      background-color: #72BEA3;
    }

    .tissue & {
      background-color: #F28860;
    }

    .coronial & {
      background-color: #8E75DC;
    }

    .sudi & {
      background-color: #E55B84;
    }

    .title {
      font-family: 'PlayfairDisplay-Bold';
      color: #FFFFFF;
      margin: 0 0 scale-rem-value(20) 0;
      @include font-size-subhead();
    }

    .copy {
      color: #FFFFFF;
      margin: 0;
      @include font-size-body-copy();
    }
  }

  // FAQS

  .faqs {
    position: relative;
    background-color: #363843;
    padding: scale-rem-value(140) scale-rem-value(40) scale-rem-value(40) scale-rem-value(40);
    margin: scale-rem-value(-100) 0 0 0;
    display: flex;
    flex-direction: column;

    &.normal-padding {
      padding: scale-rem-value(40);
    }

    @include breakpoint(md) {
      flex-direction: row;
      padding: scale-rem-value(100) scale-rem-value(100) scale-rem-value(80) scale-rem-value(100);
      margin: scale-rem-value(-40) 0 0 0;
      width: calc(100vw - 4.16667rem);
      align-self: flex-end;
    }

    .title {
      font-family: 'PlayfairDisplay-Bold';
      color: #FFFFFF;
      margin: 0 0 scale-rem-value(60) 0;
      @include font-size-subhead();

      @include breakpoint(md) {
        width: 40%;
        padding: 0 scale-rem-value(200) 0 0;
        margin: 0;
      }
    }

    .accordion {
      display: flex;
      flex-direction: column;

      @include breakpoint(md) {
        width: 60%;
      }

      .panel {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0 0 scale-rem-value(40) 0;

        &:after {
          content: '';
          position: absolute;
          display: block;
          bottom: scale-rem-value(-10);
          left: 0;
          width: 100%;
          height: scale-rem-value(1);
          background-color: #E9DCCD;
        }

        &.active {
          .btn svg {
            transform: rotate(180deg);
          }

          .content {
            margin: 0 0 scale-rem-value(10) 0;
          }
        }

        .btn {
          outline: none;
          border: none;
          background: none;
          text-align: start;
          margin: 0 0 scale-rem-value(10) 0;
          padding: 0;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 100%;
          justify-content: space-between;
          font-weight: bold;
          @include font-size-title-copy();

          .label {
            pointer-events: none;
            flex: 1 0 auto;
          }

          svg {
            pointer-events: none;
            width: 17px;
            height: 17px;
            margin: 0 0 0 scale-rem-value(30);
            transition: transform 0.35s ease-out;

            @include breakpoint(md) {
              width: 25px;
              height: 25px;
            }
          }
        }

        .content {
          color: #FFFFFF;
          margin: 0;
          overflow:hidden;
          max-height: 0;
          transition: max-height 0.35s ease-out;
          @include font-size-body-copy();

          a {
            color: #FFFFFF;
          }
        }
      }

      .get-in-touch {
        color: #FFFFFF;
        @include font-size-title-copy();

        a {
          [data-page-id="bereavement-care"] & {
            color: #72BEA3;
          }
    
          [data-page-id="tissue-return"] & {
            color: #F28860;
          }
    
          [data-page-id="coronial-support"] & {
            color: #8E75DC;
          }
    
          [data-page-id="sudi-information"] & {
            color: #E55B84;
          }
        }
      }
    }
  }

  // Useful Links

  .useful-links {
    padding: scale-rem-value(40);
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      padding: scale-rem-value(100);
      flex-direction: row;
    }

    .title {
      font-family: 'PlayfairDisplay-Bold';
      color: #363843;
      margin: 0 0 scale-rem-value(20) 0;
      @include font-size-subhead();

      @include breakpoint(md) {
        width: 40%;
      }
    }

    .cards {
      @include breakpoint(md) {
        width: 60%;
        padding: 0 scale-rem-value(200) 0 0;
      }
      
      @include breakpoint(lg) {
        padding: 0 scale-rem-value(310) 0 0;
      }

      .card {
        margin: scale-rem-value(20) 0;
  
        .link {
          @include font-size-title-copy();
          font-weight: bold;
          margin: 0;

          [data-page-id="bereavement-care"] & {
            color: #72BEA3;
          }
    
          [data-page-id="tissue-return"] & {
            color: #F28860;
          }
    
          [data-page-id="coronial-support"] & {
            color: #8E75DC;
          }
    
          [data-page-id="sudi-information"] & {
            color: #E55B84;
          }

          @include breakpoint(md) {
            font-size: scale-rem-value(16);
          }

          @include breakpoint(lg) {
            font-size: scale-rem-value(24);
          }
        }
  
        .copy {
          color: #363843;
          margin: 0;
          @include font-size-body-copy();
        }
      }
    }
  }
}