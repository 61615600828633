.navbar {
    top: 0;
    position: fixed;
    width: 100vw;
    height: 70px;
    background-color: white;
    box-shadow: 1px 1px 8px -2px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;

    @include breakpoint(md) {
        width: scale-rem-value(800);
        height: scale-rem-value(90);
        position: absolute;
        box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.02);
    }

    @include breakpoint(lg) {
        width: scale-rem-value(980);
        height: scale-rem-value(120);
    }

    .logo {
        margin: 15px;
        width: 100px;

        @include breakpoint(md) {
            width: scale-rem-value(100);
            margin: 0 scale-rem-value(85) 0 scale-rem-value(50);
        }

        @include breakpoint(lg) {
            width: scale-rem-value(150);
            margin: 0 scale-rem-value(166) 0 scale-rem-value(85);
        }
    }

    .btn-menu {
        width: 35px;
        height: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 12px 25px 0 0;

        .line {
            display: block;
            margin: 5px;
            width: 35px;
            height: 2px;
            background-color: $darkBlue;
            transition: all ease 0.3s;
        }

        #line1.active {
            transform: translate(0px, 0px) rotate(45deg);
        }

        #line2.active {
            transform: translate(0px, -12px) rotate(-45deg);
        }

        @include breakpoint(md) {
            display: none;
        }
    }

    .menu {
        position: fixed;
        top: 70px;
        width: 100%;
        height: 100%;
        background-color: $darkBlue;
        margin: 0;
        padding: scale-rem-value(15) scale-rem-value(20) scale-rem-value(30)
            scale-rem-value(20);
        z-index: 20;
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            position: relative;
            opacity: 1;
            visibility: visible;
            background-color: transparent;
            padding: 0;
            top: 0;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }

        > li {
            position: relative;
            list-style-type: none;
            margin: scale-rem-value(5) 0px;

            @include breakpoint(md) {
                margin: 0px 15px;
                height: scale-rem-value(120);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            a {
                font-family: "PlayfairDisplay-Bold";
                text-decoration: none;
                color: white;
                font-size: scale-rem-value(34);
                transition: color 0.35s ease;

                @include breakpoint(md) {
                    font-family: "OpenSans";
                    color: #363843;
                    font-size: scale-rem-value(16);
                    font-weight: bold;
                }

                &.active {
                    color: #bf5152;

                    @include breakpoint(md) {
                        position: relative;
                        &:after {
                            content: "";
                            position: absolute;
                            display: block;
                            width: 100%;
                            bottom: -10px;
                            left: 0px;
                            height: 4px;
                            background-color: #bf5152;
                        }
                    }
                }
            }

            .arrow {
                position: relative;
                margin: 4px 0 0 5px;
                width: 9px;
                display: none;
                transform-origin: center;
                transition: transform 0.35s ease;

                @include breakpoint(md) {
                    display: block;
                }
            }

            @include breakpoint(md) {
                &:hover {
                    > a {
                        color: #bf5152;
                    }

                    .arrow {
                        transform: rotate(180deg);
                        svg path {
                            stroke: #bf5152;
                        }
                    }

                    .submenu {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .submenu {
                position: relative;
                padding: 0 0 0 scale-rem-value(20);
                display: flex;
                flex-direction: column;
                transition: opacity 0.35s ease;

                @include breakpoint(md) {
                    position: absolute;
                    top: scale-rem-value(105);
                    left: 0;
                    padding: 0;
                    background-color: #ffffff;
                    padding: scale-rem-value(22) scale-rem-value(25);
                    opacity: 0;
                    visibility: hidden;
                    box-shadow: 0px 10px 5px 2px rgba(0, 0, 0, 0.02);

                    @include breakpoint(lg) {
                        top: scale-rem-value(120);
                    }

                    &:before {
                        position: absolute;
                        content: "";
                        width: calc(100% - scale-rem-value(44));
                        height: 1px;
                        top: 0;
                        left: scale-rem-value(22);
                        background-color: #d8d8d8;
                    }
                }

                li {
                    list-style-type: none;
                    margin: scale-rem-value(10) 0px;

                    @include breakpoint(md) {
                        margin: scale-rem-value(10) 0px;

                        &:hover {
                            > a {
                                color: #bf5152;
                            }
                        }
                    }

                    a {
                        font-family: "OpenSans";
                        font-size: scale-rem-value(20);
                        transition: color 0.35s ease;

                        @include breakpoint(md) {
                            font-size: scale-rem-value(16);
                            white-space: nowrap;

                            &.active {
                                &:after {
                                    display: none;
                                }
                            }
                        }

                        span {
                            font-size: scale-rem-value(12);
                        }
                    }
                }
            }
        }

        .social {
            display: flex;
            padding: 0;
            margin: scale-rem-value(40) 0 0 0;

            @include breakpoint(md) {
                display: none;
            }

            li {
                list-style-type: none;

                a {
                    margin: 0 scale-rem-value(26) 0 0;
                    svg path {
                        fill: #ffffff;
                    }
                }
            }
        }
    }
}

.sudi-info {
    font-size: scale-rem-value(10);
    line-height: 1;

    @include breakpoint(md) {
            font-size: scale-rem-value(14);
    }
}