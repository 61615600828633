.about-content {
    width: 90%;
    margin: scale-rem-value(20) auto scale-rem-value(-20) auto;
    padding: 0 0 scale-rem-value(40) 0;
    background-color: $beige;
    
    @include breakpoint(md) {
        margin: scale-rem-value(80) auto scale-rem-value(-100) auto;
        padding: scale-rem-value(80) 0;
    }
    
    @include breakpoint(lg) {
        margin: scale-rem-value(80) auto scale-rem-value(-160) auto;
    }
    
    .sm-title {
        position: relative;
        left: scale-rem-value(-20);
        top: scale-rem-value(300);
        display: flex;
        align-items: center;
        
        @include breakpoint(md) {
            top: 100vh;
            left: scale-rem-value(-50);
            transform-origin: left;
            transform: rotate(-90deg);
        }
        
        .line {
            width: scale-rem-value(30);
            height: 1px;
            background-color:$darkBlue;
            margin-right: scale-rem-value(10);
            
            @include breakpoint(md) {
                width: scale-rem-value(80);
            }
        }
        
        h6 {
            color:$darkBlue;
            font-size: scale-rem-value(12);
            
            @include breakpoint(md) {
                font-size: scale-rem-value(15);
                font-weight: bold;
            }
        }
    }
    
    .section {
        padding: 0 scale-rem-value(20) scale-rem-value(80) scale-rem-value(20);
        display: flex;
        flex-direction: column;
        
        @include breakpoint(md) {
            padding: 0 scale-rem-value(130) scale-rem-value(100) scale-rem-value(130);
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .image {
                // margin: 0 150rem 0 0;
            }
            
            &:nth-child(odd) {
                flex-direction: row-reverse;
                .image {
                    // margin: 0 0 0 150rem;
                    // padding: 0 0 0 100rem;
                }
            }
        }

        @include breakpoint(lg) {
            padding: 0 scale-rem-value(200) scale-rem-value(100) scale-rem-value(200);
        }

        > svg {
            height: scale-rem-value(250) !important;
            @include breakpoint(md) {
                height: scale-rem-value(400) !important;
            }
        }
        
        .image {
            display: block;
            width: scale-rem-value(250);
            height: auto;
            margin: 0 auto;

            @include breakpoint(md) {
                margin: 0;
                width: 25%;
                // padding: 0 100rem 0 0;
                &svg {
                    height: scale-rem-value(400);
                }
            }
        }
        
        .copy {
            display: flex;
            flex-direction: column;

            @include breakpoint(md) {
                width: 60%;
            }
            
            h1 {
                padding-top: 30px; 
                margin: scale-rem-value(40) auto scale-rem-value(20) auto;
                color:$darkBlue;
                font-family: 'PlayfairDisplay-Bold';
                @include font-size-subhead();

                @include breakpoint(md) {
                    padding: 0;
                    margin: 0 0 scale-rem-value(45) 0;
                }
            }
            
            p {
                color: $darkBlue;
                margin: 0 auto;
                @include font-size-body-copy();

                @include breakpoint(lg) {
                    padding: 0 scale-rem-value(200) 0 0;
                }
            }

            .btn-more {
                color: #363843;
                margin: scale-rem-value(40) 0 0 0;

                @include breakpoint(md) {
                    margin: scale-rem-value(80) 0 0 0;
                }

                label {
                    border-bottom-color: #BF5152;
                }

                svg path {
                    stroke: #363843;
                }
            }
        }
    }
}