.footer {
  padding: scale-rem-value(60) scale-rem-value(30);
  background-color:$darkBlue;
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    padding: scale-rem-value(115) scale-rem-value(90) scale-rem-value(90) scale-rem-value(90);
    flex-direction: row;
    align-items: flex-start;
  }

  .logo {
    margin: 0 0 scale-rem-value(57) 0;
    width: scale-rem-value(88);

    @include breakpoint(md) {
      margin: 0 scale-rem-value(139) 0 0;
      width: scale-rem-value(135);
      flex-direction: row;

      g {
        transition: fill 0.35s ease;
      }
      // &:hover {
      //   g {
      //     fill: #b40023;
      //   }
      // }
    }

    @include breakpoint(lg) {
      width: scale-rem-value(165);
    }
  }

  .container {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1;
    }

    .links {
      display: flex;
      justify-content: space-between;
      list-style-type: none;
      flex-wrap: wrap;
      padding-inline-start: 0px;

      @include breakpoint(md) {
        width: scale-rem-value(540);
        justify-content: flex-start;
        padding: 0;
        margin: 0;
      }

      li {
        padding: scale-rem-value(10) 0px;
        width: 50%;

        @include breakpoint(md) {
          padding: 0;
          margin: 0;
          width: scale-rem-value(230);
        }

        a {
          color:white;
          text-decoration: none;
          font-size: scale-rem-value(14);

          @include breakpoint(md) {
            transition: color 0.35s ease;
            font-size: scale-rem-value(17);
            &:hover {
              color: #BF5152;
            }
          }
        }
      }
    }

    .social {
      display: flex;
      padding: 0;
      margin: scale-rem-value(57) 0 scale-rem-value(38) 0;

      @include breakpoint(md) {
        flex: 1;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
      }

      li {
        list-style-type: none;

        a {
          margin: 0 scale-rem-value(26) 0 0;
          text-decoration: none;

          @include breakpoint(md) {
            transition: opacity 0.35s ease;
            &:hover {
              opacity: 0.7;
              svg {
                transform: scale(0.95);
              }
            }
          }

          svg {
            width: scale-rem-value(40);
            height: scale-rem-value(40);
            transition: transform 0.35s ease;
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }

    .terms {
      color:white;

      @include breakpoint(md) {
        min-width: 100%;
        margin: 20px 0 0 0;
      }

      a {
        color:white;
        text-decoration: none;
        font-size: scale-rem-value(14);

        @include breakpoint(md) {
          transition: color 0.35s ease;
          font-size: scale-rem-value(17);
          &:hover {
            color: #BF5152;
          }
        }
      }
    }
  }
}