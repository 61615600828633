.services {
    position: relative;
    background-color: $darkBlue;
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
        padding: scale-rem-value(100) 0 0 0;
    }

    @include breakpoint(lg) {
        padding: scale-rem-value(130) 0 0 0;
    }

    &:after {
        content: '';
        bottom: 0;
        left: 0;
        width: 80%;
        height: scale-rem-value(5);
        background-color: #E9DCCD;

        @include breakpoint(md) {
            width: 50%;
            height: scale-rem-value(15);
        }
    }

    > .title {
        font-family: 'PlayfairDisplay-Bold';
        color: #FFFFFF;
        padding: scale-rem-value(50) scale-rem-value(40);
        margin: 0;
        @include font-size-headline();

        @include breakpoint(md) {
            padding: scale-rem-value(50) scale-rem-value(80);
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        margin: 0 0 scale-rem-value(60) 0;

        @include breakpoint(md) {
            flex-direction: row;
            margin: 0 0 scale-rem-value(90) 0;
        }

        &.bereavement {
            .box {
                background-color: #72BEA3;
                .btn-more label { color: #72BEA3; }
                .btn-more svg path { stroke: #72BEA3; }

                @include breakpoint(md) {
                    align-self: center;
                    margin: 0 0 0 scale-rem-value(-40);
                }
            }
        }
        
        &.coronial {
            @include breakpoint(md) {
                align-self: flex-end;
                flex-direction: row-reverse;
            }

            .box {
                background-color: #8E75DC;
                align-self: flex-end;
                .btn-more label { color: #8E75DC; }
                .btn-more svg path { stroke: #8E75DC; }
                @include breakpoint(md) {
                    align-self: center;
                    margin: 0 scale-rem-value(-40) 0 0;
                }
            }
        }

        &.sudi {
            .box {
                background-color: #E55B84;
                .btn-more label { color: #E55B84; }
                .btn-more svg path { stroke: #E55B84; }

                @include breakpoint(md) {
                    align-self: center;
                    margin: 0 0 0 scale-rem-value(-40);
                }
            }
        }
        
        &.tissue {
            @include breakpoint(md) {
                align-self: flex-end;
                flex-direction: row-reverse;
            }

            .box {
                background-color: #F28860;
                .btn-more label { color: #F28860; }
                .btn-more svg path { stroke: #F28860; }
                align-self: flex-end;
                @include breakpoint(md) {
                    align-self: center;
                    margin: 0 scale-rem-value(-40) 0 0;
                }
            }
        }

        > .image {
            width: 100vw;
            height: 90vw;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            @include breakpoint(md) {
                width: scale-rem-value(800);
                height: scale-rem-value(580);
            }
        }

        > .box {
            display: flex;
            flex-direction: column;
            width: 90vw;
            margin: scale-rem-value(-20) 0 0 0;
            padding: scale-rem-value(50) scale-rem-value(40) 0 scale-rem-value(40);

            @include breakpoint(md) {
                width: 45vw;
                padding: scale-rem-value(70) scale-rem-value(60) 0 scale-rem-value(60);
            }

            > .title {
                font-family: 'PlayfairDisplay-Bold';
                color: #FFFFFF;
                margin: 0 0 scale-rem-value(20) 0;
                @include font-size-subhead();

                @include breakpoint(md) {
                    margin: 0 0 scale-rem-value(30) 0;
                }
            }

            > .description {
                color: #FFFFFF;
                margin: 0 0 scale-rem-value(40) 0;
                @include font-size-body-copy();

                @include breakpoint(md) {
                    margin: 0 0 scale-rem-value(60) 0;
                }
            }

            > .btn-more {
                background-color: #ffffff;
                font-size: scale-rem-value(14);
                font-weight: bold;
                text-decoration: none;
                align-self: flex-end;
                padding: scale-rem-value(15) scale-rem-value(20);
                margin: 0 scale-rem-value(-40) 0 0;

                @include breakpoint(md) {
                    font-size: scale-rem-value(15);
                    margin: 0 scale-rem-value(-60) 0 0;
                    padding: scale-rem-value(20) scale-rem-value(30);
                }

                label {
                    padding: 0 0 scale-rem-value(4) 0;
                    pointer-events: none;
                    border: 0;
                    padding: 0;
                }

                svg {
                    pointer-events: none;
                    margin: 0 0 0 scale-rem-value(30);
                    width: scale-rem-value(8);

                    @include breakpoint(md) {
                        width: scale-rem-value(15);
                        height: scale-rem-value(15);
                    }

                    @include breakpoint(lg) {
                        height: scale-rem-value(20);
                    }
                }
            }
        }
    }
}