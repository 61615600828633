$xs: only screen and (max-width: 767px);
$sm: only screen and (min-width: 768px);
$md: only screen and (min-width: 1024px);
$lg: only screen and (min-width: 1920px);

@mixin breakpoint($bp) {
  @if $bp == xs {
    @media (max-width: 767px) { @content ; }
  }
  @else if $bp == sm {
    @media (min-width: 768px) { @content ; }
  }
  @else if $bp == md {
    @media (min-width: 1024px) { @content ; }
  }
  @else if $bp == lg {
    @media (min-width: 1920px)  { @content ; }
  }
}