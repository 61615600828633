.stories-posts {
  @include breakpoint(md) {
    position: relative;
    &:after {
      content: '';
      background-color: #BF5152;
      display: block;
      width: 50%;
      height: scale-rem-value(10);
      transform: translate(100%, 0);
    }
  }

  .partials-stories-posts-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .story-post {
      display: flex;
      flex-direction: column;
      text-decoration: none;

      // HIGHLIGHT
      &:nth-child(1) {
        @include breakpoint(md) {
          flex-direction: row-reverse;
          flex: 1;
          min-width: 100vw;
          padding: 0 !important;
          margin: 0;

          &:hover {
            .image {
              background-size: cover;
            }
  
            .post-title {
              color: #E9DCCD !important;
            }
          }
        }
        
        .image {
          position: relative;
          width: 100vw;
          height: 70vw;
          margin: 0;

          @include breakpoint(md) {
            width: 50%;
            height: 47vw;
            background-size: cover;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              background-color: #BF5152;
              display: block;
              width: 70%;
              height: scale-rem-value(15);
            }
          }

          @include breakpoint(lg) {
            width: 40%;
            height: 38vw;
          }
        }
        
        .content {
          background-color: $darkBlue;
          padding: scale-rem-value(40);
          display: flex;
          flex-direction: column;
          justify-content: center;

          @include breakpoint(md) {
            align-self: flex-start;
            width: 50%;
            margin: 0;
            padding: scale-rem-value(240) scale-rem-value(80) scale-rem-value(140) scale-rem-value(80);
          }

          // @include breakpoint(lg) {
          //   padding: 240rem 80rem 140rem 80rem;
          // }

          .post-title {
            color: #ffffff;

            @include font-size-story-headline();
          }

          .post-excerpt {
            color: #ffffff;
            @include font-size-title-copy();

            @include breakpoint(md) {
              margin: scale-rem-value(30) 0 scale-rem-value(20) 0;
            }

            @include breakpoint(lg) {
              padding: 0 scale-rem-value(200) 0 0;
            }
          }

          .post-date {
            @include font-size-body-copy();
            font-style: italic;
            color: #E9DCCD;
            margin: 0 0 scale-rem-value(20) 0;
          }

          .btn-more {
            color: #ffffff;

            svg path {
              stroke: #ffffff;
            }
          }
        }
      }

      // OTHERS

      @include breakpoint(md) {
        flex-direction: row;
        width: 50%;
        margin: scale-rem-value(60) 0;

        &:hover {
          .image {
            background-size: auto 110%;
          }

          .content {
            .post-title {
              color: #BF5152;
            }
          }
        }

        &:nth-child(even) {
          padding: 0 scale-rem-value(30) 0 scale-rem-value(80);
        }

        &:nth-child(odd) {
          padding: 0 scale-rem-value(80) 0 scale-rem-value(30);
        }
      }

      .image {
        display: flex;
        width: calc(100vw - 6.666667rem);
        height: 50vw;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin: scale-rem-value(40) scale-rem-value(40) 0 scale-rem-value(40);
        transition: background 0.35s ease-in-out;
  
        @include breakpoint(md) {
          width: 50%;
          height: scale-rem-value(250);
          margin: 0;
        }
  
        @include breakpoint(lg) {
          height: scale-rem-value(350);
        }
      }

      .content {
        padding: scale-rem-value(16) scale-rem-value(40) scale-rem-value(40) scale-rem-value(40);

        @include breakpoint(md) {
          padding: 0 0 0 scale-rem-value(40);
          flex: 1;
        }

        .post-title {
          font-family: 'PlayfairDisplay-Bold';
          color: $darkBlue;
          margin: 0;
          @include font-size-story-subhead();
          transition: color 0.35s ease-in-out;
        }
        .post-date {
          color: $darkBlue;
          margin: 0 0 scale-rem-value(10) 0;
          font-style: italic;
          @include font-size-body-copy();
        }

        .post-excerpt {
          color: $darkBlue;
          margin: scale-rem-value(20) 0 scale-rem-value(6) 0;
          @include font-size-body-copy();
        }

        .btn-more {
          color: $darkBlue;

          label {
            border-bottom-color:#BF5152;
          }

          svg path {
            stroke: $darkBlue;
          }
        }
      }

    }
  }

  > .btn-more-posts {
    background-color: transparent;
    color: #ffffff;
    font-size: scale-rem-value(14);
    text-decoration: none;
    border: none;
    border-radius: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: scale-rem-value(80) auto;

    @include breakpoint(md) {
      font-size: scale-rem-value(18);
    }

    label {
      padding: 0 0 scale-rem-value(4) 0;
      color: #939393;
      pointer-events: none;
    }

    svg {
      width: scale-rem-value(12);
      height: scale-rem-value(12);
      pointer-events: none;
      transform: rotate(90deg);
      path {
        stroke: #939393;
      }
    }
  }
}