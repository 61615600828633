//colors
$darkBlue: #363843;
$red: #bf5152;
$beige: #e9dccd;
$purple: #8e75dc;
$pink: #e55b84;
$green: #8fd0b9;
$darkGreen: #72BEA3;
$orange: #f89671;

$remScale: 12;
@function scale-rem-value($nonScaledRemValue)
{
   @return #{$nonScaledRemValue / $remScale}rem;
}

// FONT SIZES

@mixin font-size-headline() {
  font-size: scale-rem-value(34);
  line-height: 1.2em;

  @include breakpoint(md) {
    font-size: scale-rem-value(60);
  }
  @include breakpoint(lg) {
    font-size: scale-rem-value(90);
  }
}

@mixin font-size-subhead() {
  font-size: scale-rem-value(30);

  @include breakpoint(md) {
    font-size: scale-rem-value(40);
  }
  @include breakpoint(lg) {
    font-size: scale-rem-value(46);
  }
}

@mixin font-size-story-headline() {
  font-size: scale-rem-value(30);
  line-height: 1.2em;

  @include breakpoint(md) {
    font-size: scale-rem-value(50);
  }
  @include breakpoint(lg) {
    font-size: scale-rem-value(70);
  }
}

@mixin font-size-story-subhead() {
  font-size: scale-rem-value(30);
  line-height: 1.2em;

  @include breakpoint(md) {
    font-size: scale-rem-value(26);
  }
  @include breakpoint(lg) {
    font-size: scale-rem-value(34);
  }
}

@mixin font-size-title-copy() {
  font-size: scale-rem-value(16);

  @include breakpoint(md) {
    font-size: scale-rem-value(18);
  }
  @include breakpoint(lg) {
    font-size: 21px;
  }
}

@mixin font-size-body-copy() {
  font-size: scale-rem-value(14);
  line-height: 1.5em;

  @include breakpoint(md) {
    font-size: scale-rem-value(16);
  }
  @include breakpoint(lg) {
    font-size: scale-rem-value(18);
  }
}