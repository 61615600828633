.promise-container{
    background-color: $beige;
    display: flex;
    flex-direction: column;
    padding: scale-rem-value(70) 0 0 0;

    @include breakpoint(md) {
        padding: scale-rem-value(150) 0 0 0;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @include breakpoint(lg) {
        padding: scale-rem-value(180) 0 0 0;
    }

    .header {
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            flex-direction: row-reverse;
        }

        img {
            width: scale-rem-value(200);
            height: auto;
            align-self: center;
    
            @include breakpoint(md) {
                width: 40vw;
                padding: 0 scale-rem-value(100);
            }
        }
    
        .copy {
            padding: scale-rem-value(40);
    
            @include breakpoint(md) {
                width: 60vw;
                padding: 0 scale-rem-value(120) 0 scale-rem-value(80);
            }

            h1 {
                color:$darkBlue;
                font-family: 'PlayfairDisplay-Bold';
                @include font-size-headline();
                margin: 0;
            }
        
            p {
                color: $darkBlue;
                margin: scale-rem-value(20) 0 0 0;
                @include font-size-body-copy();

                @include breakpoint(md) {
                    margin: scale-rem-value(40) 0 0 0;
                }
            }
        }
    }

    .video {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include breakpoint(md) {
            flex-direction: row;
            flex-wrap: wrap;
            margin: scale-rem-value(100) 0 0 0;
        }

        iframe {
            position: relative;
            width: 100vw;
            height: 56vw;
            z-index: 1;
        }
        video {
            position: relative;
            width: 100vw;
            height: 56vw;
            z-index: 1;
            background-color: black;
        }

        .white-box {
            position: relative;
            background-color: white;
            width: 100vw;
            height: scale-rem-value(70);
            margin: scale-rem-value(-40) 0 0 0;

            @include breakpoint(md) {
                margin: scale-rem-value(-75) 0 0 0;
                height: scale-rem-value(150);
            }
            
            &:after {
                content: '';
                width: 45%;
                height: scale-rem-value(5);
                position: absolute;
                bottom: 0;
                background-color: $red;

                @include breakpoint(md) {
                    height: scale-rem-value(20);
                    background-color: #E9DCCD;
                }
            }
        }

    }
}