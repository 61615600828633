.stories-article {
  position: relative;
  margin: 0 0 scale-rem-value(40) 0;

  @include breakpoint(md) {
    margin: 0 0 scale-rem-value(150) 0;
  }

  .blue-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50vw;
    background-color: $darkBlue;

    @include breakpoint(md) {
      top: scale-rem-value(-80);
      height: 45vw;
    }
  }

  .image {
    position: relative;
    width: 90vw;
    height: 60vw;
    margin: 0 5vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .label {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: #363843;
      color: #ffffff;
      font-size: scale-rem-value(10);
      font-weight: bold;
      padding: scale-rem-value(5) scale-rem-value(10);

      &.hide {
        display: none;
      }

      @include breakpoint(md) {
        font-size: scale-rem-value(20);
        padding: scale-rem-value(10) scale-rem-value(20);
      }
    }

    @include breakpoint(md) {
      margin: scale-rem-value(80) 5vw;
      height: 50vw;
    }
  }

  .title {
    font-family: 'PlayfairDisplay-Bold';
    line-height: 1.1em;
    color: $darkBlue;
    margin: scale-rem-value(40) scale-rem-value(40) 0 scale-rem-value(40);
    @include font-size-story-headline();

    @include breakpoint(md) {
      margin: scale-rem-value(40) scale-rem-value(300) 0 scale-rem-value(300);
    }

    @include breakpoint(lg) {
      margin: scale-rem-value(40) scale-rem-value(530) 0 scale-rem-value(530);
    }
  }

  .date {
    font-size: scale-rem-value(12);
    color: $darkBlue;
    margin: 21px scale-rem-value(40) scale-rem-value(70) scale-rem-value(40);

    @include breakpoint(md) {
      font-size: scale-rem-value(20);
      margin: 21px scale-rem-value(300) scale-rem-value(70) scale-rem-value(300);
    }

    @include breakpoint(lg) {
      margin: scale-rem-value(10) scale-rem-value(530) scale-rem-value(30) scale-rem-value(530);
    }
  }

  li > p {
    margin: scale-rem-value(10) 0;
  }

  ul, p {
    color: $darkBlue;
    margin: scale-rem-value(10) scale-rem-value(40);
    @include font-size-body-copy();

    @include breakpoint(md) {
      margin: scale-rem-value(25) scale-rem-value(300);
    }

    @include breakpoint(lg) {
      margin: scale-rem-value(30) scale-rem-value(530);
    }

   iframe {
      width: 100vw !important;
      margin: 0 0 0 scale-rem-value(-40);
      height: 60vw;

      @include breakpoint(md) {
        width: 120% !important;
        height: scale-rem-value(480);
        margin: 0 0 0 -10%;
      }
    }

    img {
      width: 100vw !important;
      margin: 0 0 0 scale-rem-value(-40);
      height: auto;

      @include breakpoint(md) {
        width: 120% !important;
        margin: 0 0 0 -10%;
      }
    }
  }
}

.stories-related {
  background-color: $beige;
  padding: scale-rem-value(20) 0 scale-rem-value(80) 0;
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
  }

  .story-post {
    display: flex;
    flex-direction: column;
    text-decoration: none;

    @include breakpoint(md) {
      flex-direction: row;
      width: 50%;
      margin: scale-rem-value(60) 0;

      &:hover {
        .image {
          background-size: auto 110%;
        }
      }

      &:nth-child(1) {
        padding: 0 scale-rem-value(30) 0 scale-rem-value(80);
      }

      &:nth-child(2) {
        padding: 0 scale-rem-value(80) 0 scale-rem-value(30);
      }
    }

    .image {
      display: flex;
      width: calc(100vw - 6.666667rem);
      height: 50vw;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin: scale-rem-value(40) scale-rem-value(40) 0 scale-rem-value(40);
      transition: background 0.35s ease-in-out;

      @include breakpoint(md) {
        width: 50%;
        height: scale-rem-value(200);
        margin: 0;
      }

      @include breakpoint(lg) {
        height: scale-rem-value(350);
      }
    }

    .content {
      padding: scale-rem-value(16) scale-rem-value(40) scale-rem-value(40) scale-rem-value(40);

      @include breakpoint(md) {
        padding: 0 0 0 scale-rem-value(40);
        flex: 1;
      }

      .post-title {
        font-family: 'PlayfairDisplay-Bold';
        color: $darkBlue;
        margin: 0;
        @include font-size-story-subhead();
      }
      .post-date {
        color: $darkBlue;
        margin: 0 0 scale-rem-value(30) 0;
        font-style: italic;
        @include font-size-body-copy();
      }
      .post-excerpt {
        font-size: scale-rem-value(14);
        color: $darkBlue;
        @include font-size-body-copy();
      }

      .btn-more {
        color: $darkBlue;

        label {
          border-bottom-color:#BF5152;
        }

        svg path {
          stroke: $darkBlue;
        }
      }
    }
  }
}