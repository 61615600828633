.testimonial-slider {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $darkBlue;

    @include breakpoint(md) {
        background-color: transparent;
        flex-direction: row-reverse;
        padding: 0;
        margin: scale-rem-value(-40) 0 0 0;
    }

    .bottom-bar {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $beige;
        display: block;
        width: 100%;
        height: scale-rem-value(4);
        
        @include breakpoint(md) {
            height: scale-rem-value(15);
        }
    }

    .bg-slides {
        position: relative;
        list-style: none;
        padding: 0;
        margin: scale-rem-value(-30) 0 0 0;
        box-sizing: border-box;
        width: 311px;
        height: scale-rem-value(300);
        align-self: flex-end;

        .square {
            position: absolute;
            top: 0;
            right: 0;
            background-color: #BF5152;
            display: flex;
            width: 100%;
            height: 100%;
            z-index: 1;
            transform-origin: right;
            transform: scaleX(0);
        }

        @include breakpoint(md) {
            width: 40%;
            height: 41vw;
        }

        @include breakpoint(lg) {
            height: scale-rem-value(900);
        }

        li {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            display: none;
            &.active {
                display: block;
            }
        }
    }
    
    .content-wrapper {
        position: relative;
        padding: scale-rem-value(55) scale-rem-value(40) scale-rem-value(28) scale-rem-value(40);
        margin: 0;
        min-height: 90vh;
        box-sizing: border-box;
        background-color: $darkBlue;

        @media (-webkit-min-device-pixel-ratio: 3.5) {
            min-height: 70vh;
        }

        @media (-webkit-min-device-pixel-ratio: 4) {
            min-height: 90vh;
        }
        
        @include breakpoint(md) {
            flex: 1;
            min-height: auto;
            margin: 90px 0 0 0;
            padding: scale-rem-value(50) scale-rem-value(100);
            align-self: flex-end;
        }
        
        @include breakpoint(lg) {
            padding: scale-rem-value(100) scale-rem-value(100) scale-rem-value(50) scale-rem-value(100);
        }
        
        .sm-title {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            z-index: 1;
            
            @include breakpoint(md) {
                top: 50%;
                left: scale-rem-value(30);
                transform-origin: left;
                transform: rotate(-90deg);
            }
            
            .line {
                width: scale-rem-value(30);
                height:1px;
                background-color: white;
                margin-right: 10px;
                
                @include breakpoint(md) {
                    width: scale-rem-value(80);
                }
            }
            h6 {
                color:white;
                font-size: scale-rem-value(12);
                
                @include breakpoint(md) {
                    font-size: scale-rem-value(15);
                    font-weight: bold;
                }
            }
        }
        
        .content-slides {
            position: relative;
            padding: 0;
            margin: 0;
            
            li {
                display: none;
                
                &.active {
                    display: inherit;
                }
                
                h1 {
                    font-family: 'PlayfairDisplay-Bold';
                    margin:0;
                    padding:0 0 scale-rem-value(25) 0;
                    color: #ffffff;
                    font-size: 21px;
                    
                    @include breakpoint(md) {
                        padding:0 0 scale-rem-value(40) 0;
                        font-size: scale-rem-value(28);
                    }
                    
                    @include breakpoint(lg) {
                        padding:0 0 scale-rem-value(70) 0;
                        font-size: scale-rem-value(42);
                    }
                }
                
                p {
                    color: #ffffff;
                    // line-height: 20px;
                    padding: 0 0 scale-rem-value(35) 0;
                    margin: 0;
                    @include font-size-title-copy();

                    @include breakpoint(md) {
                        padding: 0 0 5vh 0;
                    }
                }
            }
        }
        
        .btn-more {
            position: absolute;
            bottom: scale-rem-value(100);
            left: scale-rem-value(40);

            @include breakpoint(md) {
                left: scale-rem-value(100);
                bottom: 17vh;
            }

            @include breakpoint(lg) {
                bottom: 15vh;
            }
        }
        
        .slide-counter {
            display: none !important; // remove it for phase 2
            position: absolute;
            bottom: scale-rem-value(10);
            left: scale-rem-value(40);
            color:white;
            margin: 70px 0 0 0;
            
            @include breakpoint(md) {
                left: scale-rem-value(100);
                bottom: scale-rem-value(30);
            }
            
            p {
                display: flex;
                align-items: center;
                font-size: scale-rem-value(10);
                
                #number {
                    padding-right: 5px;
                }
                
                #line {
                    position: relative;
                    width: scale-rem-value(35);
                    height: 1px;
                    background-color: rgba(255, 255, 255, 0.3);
                    display: block;
                    margin-right: 5px;
            
                    @include breakpoint(md) {
                        width: scale-rem-value(78);
                    }
            
                    .progress-line {
                        position: absolute;
                        left: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #ffffff;
                        display: block;
                        margin-right: 5px;
                    }
                }
            }
        }
        
        .buttons {
            display: none !important;// remove it for phase 2
            position: absolute;
            right: 0;
            bottom: scale-rem-value(15);
            display: flex;
            z-index: 5;

            @include breakpoint(md) {
                right: 0;
                transform: translate(50%, 0);
            }
            
            .btn {
                width: scale-rem-value(55);
                height: scale-rem-value(55);
                border: 0;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                text-align: center;
                box-sizing: border-box;
                transition: all 0.35s ease-in-out;
                
                @include breakpoint(md) {
                    width: scale-rem-value(70);
                    height: scale-rem-value(70);
                    font-size: scale-rem-value(15);
                    cursor: pointer;
                }

                .arrow {
                    width: scale-rem-value(8);
                    pointer-events: none;
                    transition: transform 0.35s ease-out;
                    
                    @include breakpoint(md) {
                        width: scale-rem-value(16);
                    }
                    
                    // @include breakpoint(lg) {
                    //     width: 25rem;
                    // }
                }
                
                &.prev-btn {
                    background-color: #BF5152;
                    color:white;
    
                    @include breakpoint(md) {
                        flex-direction: row-reverse;

                        &:hover {
                            opacity: 0.9;
                            .arrow {
                                transform: translate(-50%, 0) rotate(-180deg);
                            }
                        }
                    }
                    .arrow {
                        transform: rotate(-180deg);
                    }
                }

                &.next-btn {
                    background-color: #ffffff;
                    svg path {
                        stroke: #363843;
                    }

                    @include breakpoint(md) {
                        &:hover {
                            opacity: 0.9;
                            .arrow {
                                transform: translate(50%, 0);
                            }
                        }
                    }
                }
            }
        }
    }
}