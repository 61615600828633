@import "breakpoints";
@import "variables";
@import "fonts";

@import "partials/global/navbar";
@import "partials/global/footer";

@import "partials/home/home-slider";
@import "partials/home/about-content";
@import "partials/home/testimonial-slider";

@import "pages/about";
@import "pages/promise";
@import "pages/contact";
@import "pages/stories";
@import "pages/stories-article";
@import "pages/services";
@import "pages/services-article";



html {
  font-size: calc((100vw / 375) * #{$remScale});

  @include breakpoint(sm) {
    font-size: calc((100vw / 768) * #{$remScale});
  }

  @include breakpoint(md) {
    font-size: calc((100vw / 1440) * #{$remScale});
  }

  @include breakpoint(lg) {
    font-size: calc((100vw / 1920) * #{$remScale});
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline-color: transparent;
    outline-style: none;
  }
}

body {
  font-family: 'OpenSans';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  // letter-spacing: 0.05em;
  margin:0;
  padding:0;
  overflow-x: hidden;

  font-size: scale-rem-value(16);
  @include breakpoint(md) {
    font-size: scale-rem-value(24);
  }
}

#layout-content{
  padding: 70px 0 0 0;
  @include breakpoint(md) {
    padding: 0;
  }
}

.bold {
  font-weight: bold;
}

[data-component="section-animate"] {
  opacity: 0;
}

.btn-more {
  color: #ffffff;
  font-size: scale-rem-value(14);
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;

  @include breakpoint(md) {
    font-size: scale-rem-value(15);
    transition: opacity 0.35s ease-in-out;

    &:hover {
      opacity: 0.8;
      svg {
        transform: translate(50%, 0);
      }
    }
  }

  label {
    padding: 0 0 scale-rem-value(4) 0;
    pointer-events: none;
    border-bottom: scale-rem-value(5) solid #72BEA3;
  }

  svg {
    width: scale-rem-value(7);
    height: scale-rem-value(7);
    pointer-events: none;
    transition: transform 0.35s ease-in-out;
    margin: 0 0 0 scale-rem-value(6);
  }
}