@font-face {
  font-family: 'PlayfairDisplay-Bold';
  src: url('../fonts/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/PlayfairDisplay-Bold.woff') format('woff'), url('../fonts/PlayfairDisplay-Bold.ttf')  format('truetype'), url('../fonts/PlayfairDisplay-Bold.svg#PlayfairDisplay-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/OpenSans-Regular.woff') format('woff'), url('../fonts/OpenSans-Regular.ttf')  format('truetype'), url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/OpenSans-Bold.woff') format('woff'), url('../fonts/OpenSans-Bold.ttf')  format('truetype'), url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),  url('../fonts/OpenSans-Italic.woff') format('woff'), url('../fonts/OpenSans-Italic.ttf')  format('truetype'), url('../fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/OpenSans-BoldItalic.woff') format('woff'), url('../fonts/OpenSans-BoldItalic.ttf')  format('truetype'), url('../fonts/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}