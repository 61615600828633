.about-container{
    display: flex;
    flex-direction: column;
    background-color: $darkBlue;

    @include breakpoint(md) {
        flex-direction: row;
        flex-wrap: wrap;
        background-color: none;
    }

    .header {
        @include breakpoint(md) {
            display: flex;
            flex-direction: row-reverse;
        }

        .header-image {
            position: relative;
            width: 100vw;
            height: 60vw;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            @include breakpoint(md) {
                width: 50vw;
                height: scale-rem-value(789);
            }

            @include breakpoint(lg) {
                height: scale-rem-value(969);
            }
    
            .label {
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: #E9DCCD;
                color: #363843;
                font-size: scale-rem-value(10);
                font-weight: bold;
                padding: scale-rem-value(5) scale-rem-value(10);

                @include breakpoint(md) {
                    font-size: scale-rem-value(20);
                    padding: scale-rem-value(10) scale-rem-value(20);
                }
            }
        }

        .copy {
            position: relative;
            background-color: $darkBlue;
            padding: scale-rem-value(40);

            @include breakpoint(md) {
                padding: scale-rem-value(150) scale-rem-value(80) 0 scale-rem-value(80);
                width: 50vw;
            }

            @include breakpoint(lg) {
                padding: scale-rem-value(180) scale-rem-value(80) 0 scale-rem-value(80);
            }

            h1 {
                font-family: 'PlayfairDisplay-Bold';
                color:#ffffff;
                margin: 0 0 scale-rem-value(10) 0;
                @include font-size-headline();
            }

            p {
                margin: 0 0 scale-rem-value(10) 0;
                color:#ffffff;
                @include font-size-body-copy();
            }
        
            h2 {
                color:white;
                font-family: 'PlayfairDisplay-Bold';
                margin: 0 0 scale-rem-value(10) 0;
                padding-top: 1em;
                @include font-size-subhead();
                
                @include breakpoint(md) {
                    padding-top: 1em;
                }
                
                // @include breakpoint(lg) {
                //     padding-top: 1em;
                // }
            }

            .red-box-wrapper {
                display: flex;
                justify-content: flex-end;
                background-color: $red;
                margin: scale-rem-value(40) scale-rem-value(-40) 0 0;
                padding: scale-rem-value(20) scale-rem-value(20) scale-rem-value(40) scale-rem-value(20);
        
                @include breakpoint(md) {
                    position: absolute;
                    bottom: scale-rem-value(-120);
                    left: scale-rem-value(80);
                    margin: 0;
                    padding: scale-rem-value(40) scale-rem-value(40) scale-rem-value(70) scale-rem-value(40);
                    z-index: 2;
                }

                @include breakpoint(lg) {
                    padding: scale-rem-value(40) scale-rem-value(40) scale-rem-value(90) scale-rem-value(40);
                }
        
                .red-box {
                    width: 95vw;

                    @include breakpoint(md) {
                        width: scale-rem-value(800);
                    }

                    @include breakpoint(lg) {
                        width: scale-rem-value(920);
                    }

                    .line {
                        display: flex;
                        flex-direction: column;
                        margin: scale-rem-value(10) 0;

                        @include breakpoint(md) {
                            flex-direction: row;
                            align-items: center;
                            margin: 0;
                        }

                        @include breakpoint(lg) {
                            margin: scale-rem-value(10) 0;
                        }

                        h1 {
                            // margin: 0 0 10rem 0;
                            @include font-size-subhead();
                        }
                        p {
                            font-weight: bold;
                            @include font-size-title-copy();

                            @include breakpoint(md) {
                                margin: 0 0 0 scale-rem-value(10);
                            }

                            @include breakpoint(lg) {
                                margin: scale-rem-value(3) 0 0 scale-rem-value(10);
                            }
                        }
                    }
                }
            }
        }
    }

    .video {
        position: relative;
        margin: scale-rem-value(-60) 0 0 0;
        width: 100vw;
        height: 60vw;

        @include breakpoint(md) {
            width: 52vw;
            height: 29.2vw;
            z-index: 3;
            margin: scale-rem-value(90) 0 scale-rem-value(-30) 0;
        }
        
        iframe {
            width: 100%;
            height: 100%;
        }

        video {
            background-color: black;
            width: 100%;
            height: 100%;
        }

        .label {
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: #BF5152;
            color: #ffffff;
            font-size: scale-rem-value(10);
            font-weight: bold;
            padding: scale-rem-value(5) scale-rem-value(10);
            transform: translate(0, 100%);

            @include breakpoint(md) {
                left: auto;
                right: 0;
                font-size: scale-rem-value(20);
                padding: scale-rem-value(10) scale-rem-value(20);
            }
        }
    }

    .history {
        position: relative;
        background-color: $darkBlue;
        padding: scale-rem-value(40);

        @include breakpoint(md) {
            width: 48vw;
            padding: scale-rem-value(180) scale-rem-value(140) scale-rem-value(80) scale-rem-value(100);
            margin: 0;
        }

        @include breakpoint(lg) {
            padding: scale-rem-value(180) scale-rem-value(280) scale-rem-value(80) scale-rem-value(120);
        }

        h1 {
            font-family: 'PlayfairDisplay-Bold';
            color:#ffffff;
            margin: 0 0 scale-rem-value(10) 0;
            @include font-size-subhead();
        }
    
        p {
            margin: 0;
            color:#ffffff;
            @include font-size-body-copy();
        }

        h2 {
            color:white;
            font-family: 'PlayfairDisplay-Bold';
            margin: 0;
            font-size: 1.3em;
            padding-top:1em;

            @include breakpoint(md) {
                font-size: scale-rem-value(38);
            }
    
            @include breakpoint(lg) {
                font-size: scale-rem-value(43);
            }
        }
    }

    .white {
        background-color: white;
        width: 100%;
        position: relative;

        .who-we-are {
            background-color: $beige;
            width: calc(100vw - 3.33333rem);
            padding: scale-rem-value(60) scale-rem-value(40);
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            @include breakpoint(md) {
                width: calc(100vw - 8.33333rem);
                padding: scale-rem-value(120) scale-rem-value(50) scale-rem-value(50) scale-rem-value(50);
                flex-direction: row;
                flex-wrap: wrap;
            }
            @include breakpoint(lg) {
                padding: scale-rem-value(164) scale-rem-value(50) scale-rem-value(100) scale-rem-value(50);
            }

            h1 {
                font-family: 'PlayfairDisplay-Bold';
                color: $darkBlue;
                margin: 0 0 scale-rem-value(10) 0;
                @include font-size-subhead();

                @include breakpoint(md) {
                    width: 30%;
                }
            }
        
            > p {
                margin: 0;
                color: $darkBlue;
                @include font-size-body-copy();

                @include breakpoint(md) {
                    width: 52%;
                }

                @include breakpoint(lg) {
                    width: calc(50% - 6.666667rem);
                }
            }
        
            h2 {
                color:white;
                font-family: 'PlayfairDisplay-Bold';
                margin: 0;
                font-size: 1.3em;
                padding-top:1em;

                @include font-size-subhead();
            }

            .people {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin: scale-rem-value(60) 0 0 0;
                width: 100%;
                list-style: none;

                @include breakpoint(md) {
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: center;
                    margin: scale-rem-value(45) 0 0 0;
                }

                @include breakpoint(lg) {
                    margin: scale-rem-value(100) 0 0 0;
                }

                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    margin: scale-rem-value(35) 0;

                    @include breakpoint(md) {
                        width: 25%;
                        padding: 0 scale-rem-value(40);
                        align-items: center;
                    }

                    @include breakpoint(lg) {
                        padding: 0 scale-rem-value(60);
                    }

                    img {
                        width: scale-rem-value(250);
                        height: auto;
                        margin:auto;

                        @include breakpoint(md) {
                            width: 100%;
                        }
                    }

                    a {
                        &:hover {
                            color: #BF5152;
                        }
                    }

                    .profile-name {
                        font-family: 'PlayfairDisplay-Bold';
                        color: $darkBlue;
                        text-align: center;
                        font-size: scale-rem-value(30);
                        padding-top: 1em;
                        transition: color 0.2s ease-in-out;

                        @include breakpoint(md) {
                            font-size: scale-rem-value(28);
                            white-space: nowrap;
                            margin: 0 0 scale-rem-value(10);
                        }
                        @include breakpoint(lg) {
                            font-size: scale-rem-value(32);
                        }
                    }

                    p {
                        @include font-size-title-copy();
                        text-align: center;
                        margin: 0;
                        color: $darkBlue;

                        @include breakpoint(md) {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .logos {
            width: calc(100vw - 3.33333rem);
            padding: scale-rem-value(60) scale-rem-value(40);
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            @include breakpoint(md) {
                flex-direction: row;
            }

            h1 {
                font-family: 'PlayfairDisplay-Bold';
                color: $darkBlue;
                margin: 0;
                @include font-size-subhead();

                @include breakpoint(md) {
                    width: 30%;
                }
            }

            .logos-wrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                // padding: 40rem;

                @include breakpoint(md) {
                    width: 70%;
                    // padding: 60rem 150rem;
                }

                // @include breakpoint(lg) {
                //     padding: 100rem 240rem;
                // }

                .logo {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: scale-rem-value(35) 0;
    
                    @include breakpoint(xs) {
                        &:nth-child(odd) {
                            padding: 0 scale-rem-value(20) 0 0;
                        }
        
                        &:nth-child(even) {
                            padding: 0 0 0 scale-rem-value(20);
                        }
                    }
    
                    @include breakpoint(md) {
                        width: 25%;
                        padding: 0 3vw;
                    }
    
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .line {
            width: 70%;
            height: scale-rem-value(5);
            background-color: $red;
            position: absolute;
            bottom: 0;

            @include breakpoint(md) {
                width: 30%;
                height: scale-rem-value(15);
            }
        }
    }
}