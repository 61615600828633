.home-slider {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $beige;
  padding: 0 0 scale-rem-value(55) 0;
  
  @include breakpoint(md) {
    height: 100vh;
    // min-height: 789px;
    flex-direction: row-reverse;
    background-color: $darkBlue;
    padding: 0;
  }

  .bg-slides {
    position: relative;
    width: 100%;
    height: 80vw;
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    .square {
      position: relative;
      background-color: #8FD0B9;
      display: flex;
      width: 100%;
      height: 100%;
      z-index: 1;
      transform-origin: right;
      transform: scaleX(0);
    }

    .top-bar, 
    .bottom-bar {
      display: none;
    }

    @include breakpoint(md) {
      margin:  scale-rem-value(60) 0 0 0;
      width: scale-rem-value(740);
      height: calc(100vh -  5rem);
      padding: 0;
      // min-height: 729px;

      .top-bar {
        content: '';
        position: absolute;
        top: scale-rem-value(-10);
        right: 0;
        background-color: #8FD0B9;
        display: block;
        width: 30%;
        height: scale-rem-value(20);
        transform-origin: right;
        transform: scaleX(0);
        z-index: 1;
      }

      .bottom-bar {
        content: '';
        position: absolute;
        bottom: scale-rem-value(-20);
        right: 0;
        background-color: #8FD0B9;
        display: block;
        width: 100%;
        height: scale-rem-value(20);
        transform-origin: top;
        transform: scaleY(0);
        z-index: 1;
      }
    }

    @include breakpoint(lg) {
      width: scale-rem-value(1125);

      &:before {
        width: 50%;
      }
    }

    li {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: 50% 80%;
      background-size: cover;
      display: none;

      &.active {
        display: inherit;
      }

      @include breakpoint(md) {
        background-position: center;
      }
    }
  }

  .dots {
    position: absolute;
    top: 72vw;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: row;

    @include breakpoint(md) {
      display: none;
    }

    .dot {
      width: scale-rem-value(10);
      height: scale-rem-value(10);
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 100%;
      margin: 0 scale-rem-value(8);
      transition: background 0.35s ease-in-out;

      &.active {
        background-color: #ffffff;
      }
    }
  }

  .content-wrapper {
    position: relative;
    width: 90%;
    background-color: $darkBlue;
    padding: scale-rem-value(55) scale-rem-value(40) scale-rem-value(28) scale-rem-value(40);
    margin: 0;
    height: scale-rem-value(680);
    box-sizing: border-box;

    @include breakpoint(md) {
      flex: 1;
      width: scale-rem-value(795);
      height: calc(100vh - 90px);
      // min-height: 699px;
      margin: 90px 0 0 0;
      padding: scale-rem-value(90) scale-rem-value(100) scale-rem-value(50) scale-rem-value(100);
    }

    @include breakpoint(lg) {
      padding: scale-rem-value(100) scale-rem-value(100) scale-rem-value(50) scale-rem-value(100);
    }

    .sm-title {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      z-index: 1;
  
      @include breakpoint(md) {
        top: 50vh;
        left: scale-rem-value(30);
        transform-origin: left;
        transform: rotate(-90deg);
      }
  
      .line {
        width: scale-rem-value(30);
        height:1px;
        background-color: white;
        margin-right: 10px;
  
        @include breakpoint(md) {
          width: scale-rem-value(80);
        }
      }
      h6 {
        color:white;
        font-size: scale-rem-value(12);
        
        @include breakpoint(md) {
          font-size: scale-rem-value(15);
          font-weight: bold;
        }
      }
    }

    .content-slides {
      position: relative;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        position: absolute;
        top: 0;
        left: 0;
        display: none;

        &.active {
          display: inherit;
        }

        h1 {
          font-family: 'PlayfairDisplay-Bold';
          margin:0;
          padding:0 0 scale-rem-value(25) 0;
          color: #ffffff;
          text-transform: initial !important;
          @include font-size-headline();

          @include breakpoint(md) {
            padding:0 0 scale-rem-value(45) 0;
          }

          @include breakpoint(lg) {
            padding:0 0 scale-rem-value(70) 0;
          }
        }

        p {
          color: #ffffff;
          line-height: 20px;
          padding: 0 0 scale-rem-value(35) 0;
          margin: 0;

          @include font-size-body-copy();

          @include breakpoint(md) {
            padding: 0 0 5vh 0;
          }

          @include breakpoint(lg) {
            padding: 0 0 10vh 0;
          }
        }
      }
    }

    .btn-more {
      position: absolute;
      bottom: scale-rem-value(100);
      left: scale-rem-value(40);

      @include breakpoint(md) {
        left: scale-rem-value(100);
        bottom: scale-rem-value(80);
      }

      @include breakpoint(lg) {
        bottom: scale-rem-value(150);
      }

      label {
        border-bottom-color: #72BEA3;
      }
    }

    .slide-counter {
      position: absolute;
      bottom: scale-rem-value(10);
      left: scale-rem-value(40);
      color:white;
      margin: 70px 0 0 0;
      display: none;

      @include breakpoint(md) {
        display: inherit;
        left: scale-rem-value(100);
        bottom: scale-rem-value(20);
      }

      @include breakpoint(lg) {
        bottom: scale-rem-value(30);
      }

      p {
        display: flex;
        align-items: center;
        font-size: scale-rem-value(10);

        #number {
          padding-right: 5px;
        }

        #line {
          position: relative;
          width: scale-rem-value(35);
          height: 1px;
          background-color: rgba(255, 255, 255, 0.3);
          display: block;
          margin-right: 5px;

          @include breakpoint(md) {
            width: scale-rem-value(78);
          }

          .progress-line {
            position: absolute;
            left: 0;
            width: 0%;
            height: 2px;
            background-color: #ffffff;
            display: block;
            margin-right: 5px;
          }
        }
      }
    }

    .buttons {
      position: absolute;
      right: -11.1%;
      bottom: 0;
      transform: translate(0, 50%);
      display: flex;
      flex-direction: column-reverse;
      z-index: 5;

      @include breakpoint(md) {
        flex-direction: row;
        right: 0;
        transform: translate(50%, 0);
      }

      .btn {
        width: scale-rem-value(235);
        height: scale-rem-value(55);
        border: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color:white;
        text-align: center;
        font-size: scale-rem-value(12);
        font-weight: bold;
        padding: scale-rem-value(18) 21px;
        box-sizing: border-box;
        transition: all 0.35s ease-in-out;

        @include breakpoint(md) {
          width: scale-rem-value(370);
          height: scale-rem-value(80);
          font-size: scale-rem-value(15);
          cursor: pointer;
        }

        @include breakpoint(lg) {
          width: scale-rem-value(340);
        }

        .arrow {
          margin: 0 scale-rem-value(5) 0 0;
          pointer-events: none;
          transition: transform 0.35s ease-out;
          width: scale-rem-value(8);

          @include breakpoint(md) {
            width: scale-rem-value(16);
            height: scale-rem-value(16);
          }

          @include breakpoint(lg) {
            width: scale-rem-value(20);
            height: scale-rem-value(20);
          }
        }

        label {
          flex: 1;
          pointer-events: none;
          text-transform: uppercase;
        }
      }

      .prev-btn {
        background-color: #72BEA3;
        @include breakpoint(md) {
          flex-direction: row-reverse;

          &:hover {
            opacity: 0.9;
            .arrow {
              transform: translate(-50%, 0) rotate(-180deg);
            }
          }
        }
        .arrow {
          transform: rotate(-180deg);
        }
      }
      .next-btn {
        background-color: #363843;

        @include breakpoint(md) {
          &:hover {
            opacity: 0.9;
            .arrow {
              transform: translate(50%, 0);
            }
          }
        }
      }
    }
  }
}