.contact-container {
    position: relative;
    background-color: $darkBlue;
    padding: scale-rem-value(60) 0 scale-rem-value(40) 0;

    @include breakpoint(md) {
        padding: scale-rem-value(180) 0 scale-rem-value(40) 0;
    }

    .page-title {
        font-family: 'PlayfairDisplay-Bold';
        color:#ffffff;
        margin: 0 0 scale-rem-value(40) scale-rem-value(40);
        @include font-size-headline();
    }

    .info-box {
        position: relative;
        display: flex;
        flex-direction: column;
        width: scale-rem-value(335);
        background-color: $beige;
        padding: scale-rem-value(40) scale-rem-value(40) 0 scale-rem-value(40);
        margin-bottom: scale-rem-value(-40);
        z-index: 1;

        @include breakpoint(md) {
            width: 70%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: scale-rem-value(-5);
            width: scale-rem-value(5);
            height: 50%;
            background-color: #AC6263;

            @include breakpoint(md) {
                right: scale-rem-value(-15);
                width: scale-rem-value(15);
                height: 80%;
            }
        }

        > .title {
            font-family: 'PlayfairDisplay-Bold';
            color: $darkBlue;
            padding: 0;
            margin: 0;
            @include font-size-subhead();
        }
        
        > .description {
            color: $darkBlue;
            padding: 0;
            margin: scale-rem-value(14) 0 scale-rem-value(27) 0;
            @include font-size-title-copy();

            @include breakpoint(md) {
                margin: scale-rem-value(30) 0 scale-rem-value(60) 0;
            }
        }

        .details {
            position: relative;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;

            @include breakpoint(md) {
                flex-direction: row;
                flex-wrap: wrap;
            }

            li {
                list-style-type: none;
                display: flex;
                padding: scale-rem-value(15) 0;

                @include breakpoint(md) {
                    width: 50%;
                }
                
                .icon {
                    margin: 8px scale-rem-value(15) 0 0;
                    svg {
                        width: 35px;
                        height: 35px;
                    }
                }

                .text {
                    .title {
                        color: $darkBlue;
                        margin:0;
                        @include font-size-title-copy();
                    }
                    .description {
                        margin: 0;
                        padding-top: 0;
                        color: $darkBlue;
                        text-decoration: none;
                        @include font-size-body-copy();

                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .inc {
            margin: scale-rem-value(40) 0;
            color: #363843;
            font-size: scale-rem-value(13);

            @include breakpoint(md) {
                font-size: scale-rem-value(14);
            }
        }

        .linkedin {
            margin: scale-rem-value(20) 0 0 0;
            width: scale-rem-value(35);
            height: scale-rem-value(35);

            svg path {
                fill: #363843;
            }

            @include breakpoint(md) {
                position: absolute;
                bottom: scale-rem-value(35);
                left: 50%;
            }
        }
    }

    .map {
        position: relative;
        width: 100vw;
        height: scale-rem-value(460);
        margin: scale-rem-value(-20) 0 0 0;

        @include breakpoint(lg) {
            height: scale-rem-value(600);
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 40%;
            height: scale-rem-value(5);
            background-color: #AC6263;

            @include breakpoint(md) {
                height: scale-rem-value(10);
            }
        }
    }
}